import React from "react";
import QuoteRequestModalHomeLibrary from "../components/quote-request-modal-home-library"

export default class WhatService extends React.Component {

    
    constructor(props) {
        super(props);
        this.commercialProjectType = "509";
        this.services = [
            {
                title: 'Temporary Dumpster Rental',
                code: 'dumpster',
                ondeffCode: 'priceAndAvailabilityForm',
                image:
                {
                    alt: 'Dumpster Rental',
                    path: '/images/homepage/dumpster-rental.webp'
                }
            },
            {
                title: 'Full Service Junk Removal',
                code: 'junk-removal',
                ondeffCode: 'junkRemovalQuoteRequest',
                image:
                {
                    alt: 'Junk Removal',
                    path: '/images/homepage/junk-removal.webp'
                }
            },
            {
                title: 'Permanent Commercial Dumpsters',
                code: 'comercial-trash-service',
                initialFormData: [{ key: "project_type", value: this.commercialProjectType }],
                ondeffCode: 'commercialWasteQuoteRequest',
                image:
                {
                    alt: 'Commercial Dumpsters',
                    path: '/images/homepage/comercial-trash-service.webp'
                }
            }
        ]
        this.state =
        {
            showModal: false
        }
    }

    changeShowModal = () => {
        this.setState({showModal: !this.state.showModal})
    }

    render() {
        return (
            <div className="what-service-container">
                <div class="container what-service">
                    <div className="articles">
                        <div className="row m-0" style={{gap: '20px'}}>
                            <div className="col-12">
                                <h2>What Service Do You Need?</h2>
                            </div>
                            {
                                this.services.map(service => {
                                    return (
                                        <QuoteRequestModalHomeLibrary
                                            service={service}
                                            startVisible={false}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};