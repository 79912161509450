import React, { useRef } from "react";
import OndeffModal from "./OndeffModal";

const QuoteRequestModalHomeLibrary = (props) => {
    const childRef = useRef();

    const handleClick = () => {
        if (childRef.current) {
            childRef.current.triggerChangeShowModal();
        }
    };

    return (
        <>
            <div className="articleContainer" style={{backgroundImage: `url(${props.service.image.path})`}}>
                <div className="article" style={{ cursor: 'pointer' }} onClick={handleClick}>
                    
                    <div className="text"><span>{props.service.title}</span></div>
                </div>
            </div>
            <OndeffModal
                initialFormData={props.service.initialFormData}
                ondeffCode={props.service.ondeffCode}
                ref={childRef}
            />
        </>
    )
}

export default QuoteRequestModalHomeLibrary;