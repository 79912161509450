import React, { useState, useRef } from "react";
import OndeffModal from "./OndeffModal";
import ServiceModalSelect from "./service-select-modal";

const PopularProjectsNearYou = ({popular_projects, env}) => {
    const [ondeffCode, setOndeffCode] = useState('');

    const childRef = useRef();
    const serviceModalRef = useRef();
    const servicesObj = {
        'Dumpster Rental': 'priceAndAvailabilityForm',
        'Junk Removal': 'junkRemovalQuoteRequest',
        'Commercial Trash Service': 'commercialWasteQuoteRequest',
    }

    const handleClick = (code) => {
        if (code !== 'priceAndAvailabilityForm' && code !== 'demolitionQuoteRequest') {
            if (serviceModalRef.current) {
                serviceModalRef.current.triggerChangeShowModal();
            }
        } else {
            setOndeffCode(code);
            if (childRef.current) {
              childRef.current.triggerChangeShowModal();
            }
        }
    };
   
    return (
        <div className="popularProjectsNearYou container">
            <h2>Popular projects near you</h2>
            <ul className="popularProjects">
                {popular_projects?.map((project, index) => 
                <li key={index} className='eachProject'>
                    <a href="#" onClick={(e) => {e.preventDefault(); handleClick(project.ondeffCode)}} >
                        <div className="image">
                            <img src={project.icon.path} alt={project.icon.alt} />
                        </div>
                        <div className="text">
                            <p>{project.title}</p>
                        </div>
                    </a>
                </li>
                )}
            </ul>
            <OndeffModal
                ondeffCode={ondeffCode}
                ref={childRef}
            />
            {env.site === 'dumpster' && <div style={{display: 'none'}}>
                <ServiceModalSelect 
                    ref={serviceModalRef} 
                    availableServices={servicesObj} 
                />
            </div>
            }
        </div>
    )
}

export default PopularProjectsNearYou