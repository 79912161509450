import React from "react"
import SEO from './seo'
import FooterDumpster from './footer-dumpster'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import HeaderHomeDumpster from "./header-home-dumpster"

export default function LayoutHomeDumpster({ seo, children, env, code, bgcolor, footerColor })
{         

    const bg_color = bgcolor !== undefined ? bgcolor : 'white'   
    const fooC = footerColor !== undefined ? footerColor : ''  
    const main_style =
    {
        background:bg_color
    }
    useScrollPosition(({ prevPos, currPos }) => 
    {
        const heroTitleElement = document.querySelector('.hero-title');
        let heroTitlePosition;
        
        if  (heroTitleElement) {
            heroTitlePosition = heroTitleElement.getBoundingClientRect().bottom + window.scrollY;
        }
        
        const customPosition = heroTitlePosition ? heroTitlePosition * -1 : -80;
        // We convert to negative the value of the absolute element to match the relative value of currPos.y

        const showForm = currPos.y < customPosition;

        const header = document.getElementsByTagName('header');

        header[0].className = showForm === true ? 'showform' : '';
    })
    
    return (
        <div id="wrapper">            

            <SEO seo={seo} />
            
            <header>
                <HeaderHomeDumpster env={env} code={code} />                
            </header>

            <main style={main_style}>
                {children}
            </main>
        
            <footer className={fooC}>
                <FooterDumpster/>
            </footer> 
        </div>
    ) 
}