import React from 'react';
import Cookies from 'js-cookie' //https://www.npmjs.com/package/js-cookie

export default class ZipFinderHome extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            zip: ''
        }

        this.code = this.props.code;
        this.service = process.env.GATSBY_DRUPAL_URL !== undefined ? `${process.env.GATSBY_DRUPAL_URL}` : 'https://bookable-api-dev.azurewebsites.net';
        this.env = this.props.env;
    }

    handleSubmit = event => {
        event.preventDefault();

        const zip = this.state.zip;

        if (!this.validateValue(zip)) {
            this.showError('Zip Code is required');
            return;
        }

        if (!this.validateNumber(zip)) {
            this.showError('The zip code must have 5 number');
            return;
        }

        this.getUrl(zip, true);
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    validateValue = value => {
        return value !== '' ? true : false;
    }

    validateNumber = value => {
        const regex = new RegExp('^\\d{5}$');
        return regex.test(value);
    }

    getUrl = (zip, redirect) => {
        let endpoint = '/api/v1/kpp/service_types/' + this.code + '/zip_codes/' + zip;
        const url = this.service + endpoint;
        const headers = {};
        headers['Ocp-Apim-Subscription-Key'] = process.env.GATSBY_API_MANAGMENT_KEY;

        fetch(url,
            {
                method: 'get',
                headers: headers
            })
            .then((response) => {
                if (response.status === 400) {
                    return '/redirect/no-zip-found';
                }

                return response.json();
            })
            .then((response) => {
                this.cookies(zip);

                this.setState({ 'redirect': response });

                if (redirect) {
                    this.redirect(response);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    cookies = (zip) => {
        Cookies.set('zipcode', zip);
    }

    showError = text => {
        const input = document.getElementById(this.props.id);

        input.value = '';
        input.placeholder = text;
    }

    redirect = url => {
        if (typeof url !== 'object' && !Array.isArray(url)) {
            window.location.replace('/' + url);
        } else {
            window.location.replace('/redirect/no-zip-found');
        }
    }

    render() {
        return (
            <div className={`zip-finder dumpster ${this.props.class}`}>
                <div className="container-fluid">
                    <section className="row">

                        <div className={`header-image header-image-home col-12 ${'bk-home-' + this.env.site}`} >
                            <div className="row justify-content-center align-items-center h-100">
                                <div className="col-md-12 col-lg-8 hero-title text-center">

                                    <h1>{this.props.title}</h1>
                                    <h3>{this.props.text}</h3>

                                    <form className="row justify-content-center" onSubmit={this.handleSubmit}>
                                        <div className="col-md-12 col-lg-8 col-lg-offset-2 search">
                                            <div className="input-group" id="searcher">

                                                <input
                                                    id={this.props.id}
                                                    className="form-control"
                                                    type="search"
                                                    name="zip"
                                                    value={this.state.zip}
                                                    placeholder="Enter Zip Code"
                                                    onChange={this.handleInputChange}
                                                    maxLength='5'
                                                />

                                                <button
                                                    type="submit"
                                                    id="getStarted"
                                                    className="btn btn-primary btn-sm">{this.props.button}</button>

                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="row title">
                        <div className="col-12">
                            <h3 className="justify-content-center text-white">{this.props.bottomtext}</h3>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}