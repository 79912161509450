import React from "react";

const hows = [
    {
        text: 'Describe what you need and get matched to local pros.',
        image:
        {
            alt: 'contractors',
            path: '/images/icons/kpp/how-hometown-works-icon-1.webp'  // demolition: './contractors.png'
        }
    },
    {
        text: 'Get quotes from top-rated companies.',
        image:
        {
            alt: 'contractor',
            path: '/images/icons/kpp/how-hometown-works-icon-2.webp' // demolition: './contractor.png'
        }
    },
    {
        text: 'Compare quotes and hire the right pro for the job.',
        image:
        {
            alt: 'star contractor',
            path: '/images/icons/kpp/how-hometown-works-icon-3.webp' // demolition: './star.png'
        }
    }
]

export default class HowNearMeDumpster extends React.Component 
{    
    render() 
    {        
        return (
            <div className={this.props.page != 'home' ? "container-fuild how-demolition nearmeHowContainer" : "container-fuild how-demolition nearmeHowContainerHome"}>   
                <div class="container"> 
                    <div className="row m-0">  
                        <h2>How Hometown Works</h2>            
                        <div className="hows">
                            <div className="row">
                            {
                                hows.map((how, index) =>
                                {                                                 
                                    return(
                                        <div className="col-lg-4">
                                            <div className="how nearmeHow">
                                                <div className={index != (hows.length -1) ? "image" : "image lastIcon"}>
                                                    <img src={how.image.path} alt={how.image.alt} />
                                                    {(index === 0 || index === 1) && <hr className="dottedLine"/>}
                                                </div>
                                                <div className="text">
                                                    <p>{how.text}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};