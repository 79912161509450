import React, { useEffect } from "react";
import ZipFinderHome from "../components/zip-finder-home";
import WhatService from '../components/what-service';
import HowToGuidesImage from '../components/how-to-guides-image';
import PopularProjectsNearYou from "../components/popular-projects-near-you";
import ProjectTypesList from "../components/project-types-list";
import HowNearMeDumpster from "../components/how-nearme-dumpster";
import AreYouAProfessionalDumpster from "../components/are-you-a-professional-dumpster";
import StatesListHome from "../components/state-list-home";
import CitiesListHome from "../components/cities-list-home";
import LayoutHomeDumpster from "../components/layout-home-dumpster";

const DumpsterIndex = ({ pageContext }) => {
    const states = pageContext.states.length > 0 ? pageContext.states : [];
    const codes = pageContext.codes.length > 0 ? pageContext.codes : [];
    const env = pageContext.env;
    const project_types = pageContext.project_types
    const popular_projects = pageContext.popular_projects
    const cities = pageContext.cities.length > 0 ? pageContext.cities : [];
    const HUB = pageContext.HUB;

    if (HUB.length > 0) cities.push(HUB[0])

    const seo_desc = pageContext.seo_desc;
    const seo =
    {
        url: '/',
        env: env,
        code: 'dumpster',
        page: 'home',
        seo_desc: seo_desc
    }

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if(params.about) document.getElementById("state-list-section").scrollIntoView();
    },[])

    return (

        <LayoutHomeDumpster seo={seo} env={env} code='dumpster'>

            <ZipFinderHome
                id='home-zip-code'
                code='dumpster'
                title="Rent a Dumpster & Be Free of Your Debris"
                text="Find the top dumpster rental companies serving your hometown"
                button="Find Pros"
                bottomtext="America's #1 Source for Dumpsters and Junk Removal" 
                env={env}
            />

            <WhatService
                env={env}
            />

            <PopularProjectsNearYou popular_projects={popular_projects} env={env}/>

            <HowNearMeDumpster
                page='home'
            />

            <CitiesListHome
                cities={cities}
                code='dumpster'
                codes={codes}
                env={env} 
            />

            <HowToGuidesImage code="dumpster"/>

            <AreYouAProfessionalDumpster />


            <StatesListHome
                states={states}
                code='main'
                title='Find Dumpster Rentals in All 50 States'
                codes={codes}
                env={env}
            />

            <ProjectTypesList
                title='Popular Categories'
                project_types={project_types}
                env={env}
            />

        </LayoutHomeDumpster>
    )
};

export default DumpsterIndex;