import React, { useState, useEffect } from "react"
import HeaderMenuDumpster from "./header-menu-dumpster"
import ZipFinderHomeSmall from "./zip-finder-home-small"

export default function HeaderHomeDumpster(props) {
  const env = props.env
  const code = props.code
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState)
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }

    return () => {
      document.body.classList.remove("no-scroll")
    }
  }, [isMenuOpen])

  return (
    <nav id="sitenav" className="col-12 navbar navbar-expand-md navbar-light">
      <div className={`elements ${!isMenuOpen ? "is-visible" : ""}`}>
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded={isMenuOpen}
          aria-label="Toggle navigation"
          onClick={toggleMenu}
        >
          <span className="sr-only"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>

        <a className="nav-bar brand" href="/" title="Home">
          <img src="/images/logo-dumpster.webp" alt="logo"></img>
        </a>
      </div>

      <HeaderMenuDumpster isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />

      <ZipFinderHomeSmall
        id="header-zip-finder"
        env={env}
        code={code}
        button="Find Pros"
      />
    </nav>
  )
}
