import React from "react"

export default class CitiesListHome extends React.Component 
{  
    constructor(props)
    {
        super(props);    
        this.env = this.props.env;        
        this.code = this.props.code;
        this.codes = this.props.codes;
        this.cities = this.props.cities.length > 0 ? this.props.cities : [];      
        this.cities.sort((a,b) => (a.place > b.place) ? 1 : ((b.place > a.place) ? -1 : 0));  
    }

    pickTitle = () =>
    {               
        let title = this.env.site === 'dumpster' ? "Popular Cities for {type}" : "Popular Cities for Demolition Contractors"; 
        
        this.codes.map(ele =>
        {
            if(ele.code === this.code)
            {
                title = title.replace('{type}', ele.name);
            }
        })             

        return title;
    }

    render()
    {        
        return(

            <section className="section cities-list" style={{background: '#FFFFFF'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3>{this.pickTitle()}</h3>
                        </div>
                    </div>
                    <ul className="all-cities">
                    {                       
                        this.cities.map((city, index) => 
                        {              
                            let name = this.props.page === 'home' ? city.place : city.place.split(',')[0];

                            return (
                                <li key={index}>
                                    <a href={'/'+city.url}>{name}</a>
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
            </section>

        )
    }
}