import React from "react";

const dumpsterPosts = [
    {
        title: 'Dumpster Rental Price Guide: How to Lower Your Cost to Rent a Dumpster',
        url: '/blog/roll-off-dumpster-prices',
        image:
        {
            alt: 'how to get a cheap dumpster',
            path: '/images/homepage/howto-1.webp'
        }
    },
    {
        title: 'The Ultimate Guide to Dumpster Sizes',
        url: '/blog/the-ultimate-guide-to-dumpster-sizes',
        image:
        {
            alt: 'common roll-off dumpster sizes',
            path: '/images/homepage/howto-2.webp'
        }
    },
    {
        title: 'Dumpster Rental 101: How to Rent a Dumpster',
        url: '/blog/dumpster-rental-101',
        image:
        {
            alt: 'dumpster rental guide',
            path: '/images/homepage/howto-3.webp'
        }
    },
    {
        title: 'Dumpster Rental Terms and Conditions: A Closer Look at the Fine Print',
        url: '/blog/dumpster-rental-terms-and-conditions-a-closer-look-at-the-fine-print',
        image:
        {
            alt: 'reading every last detail',
            path: '/images/homepage/howto-4.webp'
        }
    },
    {
        title: 'Rent a Dumpster or Hire a Junk Removal Company?',
        url: '/blog/rent-a-dumpster-or-hire-a-junk-removal-company',
        image:
        {
            alt: 'Comparing junk removal service with renting a dumpster',
            path: '/images/homepage/woman-sorting-through-household-junk.webp'  // './howto-5.jpg'
        }
    },
    {
        title: "Commercial Dumpster Service Guide for Business Owners",
        url: "/blog/commercial-dumpster-service",
        image:
        {
            alt: "blue dumpster being emptied into a dump truck",
            path: '/images/homepage/commercial_dumpster_service_guide.webp'
        }
    }
];

const junkRemovalPosts = [
    {
        title: 'Junk Removal 101: A Guide to Junk Removal Services',
        url: '/blog/junk-removal-101',
        image:
        {
            alt: 'guide to junk removal services',
            path: '/images/homepage/how-junk-removal-works.webp'
        }
    },
    {
        title: 'How Much Does Junk Removal Cost?',
        url: '/blog/how-much-does-it-cost-to-haul-away-junk',
        image:
        {
            alt: 'how much does junk removal cost',
            path: '/images/homepage/hire-a-junk-removal-for-home-cleanout.webp'
        }
    },
    {
        title: 'What Is an On-Site Junk Removal Estimate?',
        url: '/blog/on-site-junk-removal',
        image:
        {
            alt: 'on-site junk removal estimate',
            path: '/images/homepage/junk-removal-quotes.webp'
        }
    },
    {
        title: 'How Do Cleanout Services Work?',
        url: '/blog/how-cleanout-services-work',
        image:
        {
            alt: 'how do cleanout services work',
            path: '/images/homepage/attic-cleanout.webp'
        }
    },
    {
        title: 'Rent a Dumpster or Hire a Junk Removal Company?',
        url: '/blog/rent-a-dumpster-or-hire-a-junk-removal-company',
        image:
        {
            alt: 'Comparing junk removal service with renting a dumpster',
            path: '/images/homepage/woman-sorting-through-household-junk.webp'  // './howto-5.jpg'
        }
    },
    {
        title: "DIY vs. Professional Junk Removal: Which Option Is Right for You?",
        url: "/blog/diy-vs-professional-junk-removal-which-option-is-right-for-you",
        image:
        {
            alt: "whick option is right for you",
            path: '/images/homepage/junk-removal-professional-with-clipboard.webp'
        }
    }
];

export default class HowToGuidesImage extends React.Component 
{     
    constructor(props)
    {
        super(props);

        this.posts = this.props.code === 'dumpster' ? dumpsterPosts : junkRemovalPosts
    }       
    render() 
    {        
        return (
            <div className="container-fuild how-to-guides-image">   
                <div class="container"> 
                    <div className="row m-0">  
                        <h2 className="col-12 text-center">How-To Guides</h2>            
                        <div className="articles">
                            <div className="row" style={{gap: '20px'}}>
                            {
                                this.posts.map(post =>
                                {                                                 
                                    return(
                                        <div className="articleContainer" style={{backgroundImage: `url(${post.image.path})`}}>
                                            <a href={post.url} className="article" >
                                                <div className="text"><span>{post.title}</span></div>
                                            </a>
                                        </div>
                                    )
                                })
                            }
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};